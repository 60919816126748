import { ChipTypeMap } from '@mui/material';
import React, { ForwardedRef, useState } from 'react';
import {
  CommonAutocompleteProps,
  CommonAutocomplete
} from 'src/ui-components/autocomplete/CommonAutocomplete';
import { MobileTopAutocomplete } from 'src/ui-components/autocomplete/MobileTopAutocomplete';
import { typedForwardRef } from 'src/utils/types/forward-ref';

const MobileAutocompleteInner = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>(
  props: CommonAutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [isOnTop, setIsOnTop] = useState(false);

  return (
    <>
      {isOnTop && <MobileTopAutocomplete onClose={(() => setIsOnTop(false)) as any} {...props} />}
      {/* always render original autocomplete */}
      <CommonAutocomplete
        {...props}
        ref={ref}
        onFocus={(e) => {
          setIsOnTop(true);
          props.onFocus && props.onFocus(e);
        }}
        open={false}
      ></CommonAutocomplete>
    </>
  );
};

export const MobileAutocomplete = typedForwardRef(MobileAutocompleteInner);
