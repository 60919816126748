import { ChipTypeMap, styled } from '@mui/material';
import {
  AutocompleteProps as MuiAutocompleteProps,
  default as MuiAutocomplete
} from '@mui/material/Autocomplete';
import React, { ForwardedRef } from 'react';
import { Box } from 'src/ui-components/Box';
import { CircularProgress } from 'src/ui-components/CircularProgress';
import { Popper } from 'src/ui-components/Popper';
import { typedForwardRef } from 'src/utils/types/forward-ref';

export const StyledAutocompletePopper = styled(Popper)<{ hideNoOptionsText: boolean }>(
  ({ theme, hideNoOptionsText }) => ({
    '& .MuiPaper-root': {
      borderRadius: 12,
      marginTop: 5,
      '& .MuiAutocomplete-noOptions': {
        display: hideNoOptionsText ? 'none' : undefined,
      },
    },
    '& .MuiAutocomplete-listbox': {
      padding: 0,
    },
    // '& .MuiAutocomplete-option': {
    //   fontSize: 13,
    // },
    '& .MuiAutocomplete-groupLabel': {
      padding: theme.spacing(2),
      color: theme.palette.text.disabled,
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      lineHeight: 'normal',
    },
    '& .MuiAutocomplete-groupUl .MuiAutocomplete-option': {
      paddingLeft: theme.spacing(2),
    },
  })
);

export type CommonAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
> = Omit<
  MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  'options' | 'getOptionLabel' | 'ref'
> & {
  optionsAsync: T[] | undefined;
  getOptionLabel: (option: T) => string;
};

const CommonAutocompleteInner = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>(
  {
    optionsAsync,
    renderOption,
    getOptionLabel,
    noOptionsText,
    multiple,
    ...props
  }: CommonAutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const renderLoadingOption = (props: React.HTMLAttributes<HTMLLIElement>) => {
    return (
      <Box
        component={'li'}
        sx={{
          // improve CSS specifity
          '&&': {
            pointerEvents: 'none',
            display: 'flex',
            justifyContent: 'center',
          },
        }}
        {...props}
        key='loading-option'
      >
        <CircularProgress size={25} />
      </Box>
    );
  };

  return (
    <MuiAutocomplete
      options={optionsAsync ?? ['loadingDummyOption' as T]}
      renderOption={optionsAsync ? renderOption : renderLoadingOption}
      // fix error with option label as undefined instead of null
      getOptionLabel={(opt) => getOptionLabel(opt as T) ?? ''}
      PopperComponent={(props) => (
        <StyledAutocompletePopper hideNoOptionsText={!noOptionsText} {...props} />
      )}
      noOptionsText={noOptionsText}
      blurOnSelect={!multiple}
      multiple={multiple}
      {...props}
    ></MuiAutocomplete>
  );
};

export const CommonAutocomplete = typedForwardRef(CommonAutocompleteInner);
