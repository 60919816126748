import { RouteObject } from 'react-router-dom';
import { AppRoutes } from 'src/router/app-routes';

export const resultListSuffixes = [
  '19590345',
  '12783994',
  '19346353',
  '09584987',
  '98586021',
  '09840123',
  '95860234',
  '89237501',
  '89273423',
  '45398450',
  '87594301',
  '98693212',
  '12039255',
  '34092123',
  '98420177',
  '84739481',
  '98345982',
  '09123908',
  '12897231',
  '90243024',
  '90483509',
  '39848932',
  '12309234',
  '90234931',
  '04953840',
  '87923483',
  '45609545',
  '70960977',
  '12546523',
  '70890796',
];
export const homeSuffixes = [
  '22145112',
  '82613934',
  '34802293',
  '73836912',
  '23782934',
  '12098532',
  '23731890',
  '82739538',
  '19230812',
  '34509864',
  '12903808',
  '56907865',
  '23894732',
  '09854403',
  '12980379',
  '34539043',
  '56093453',
  '12893789',
  '09607544',
  '84927383',
  '54690822',
  '34590834',
  '89289347',
  '34590384',
  '12897884',
  '34908452',
  '38945798',
  '12389753',
  '09485642',
  '34905902',
  '58974983',
  '90867045',
  '89274892',
  '12876367',
  '48979213',
  '45879982',
  '65809434',
];
const resultListRoutes: RouteObject[] = [
  {
    path: AppRoutes.AnnouncementResultList({} as any),
    lazy: async () => {
      const { AnnouncementResultList } = await import(
        '../../pages/announcement/AnnouncementResultList/responsive/AnnouncementResultList'
      );
      return { Component: AnnouncementResultList };
    },
    loader: () => null,
  },
  ...resultListSuffixes.map((suffix) => ({
    path: `${AppRoutes.AnnouncementResultList({} as any)}/${suffix}`,
    lazy: async () => {
      const { AnnouncementResultList } = await import(
        '../../pages/announcement/AnnouncementResultList/responsive/AnnouncementResultList'
      );
      return { Component: AnnouncementResultList };
    },
    loader: () => null,
  })),
];
const homeRoutes: RouteObject[] = [
  {
    index: true,
    lazy: async () => {
      const { Home } = await import('../../pages/Home/responsive/Home');
      return { Component: Home };
    },
    loader: () => null,
  },
  ...homeSuffixes.map((suffix) => ({
    path: `${AppRoutes.Home()}/${suffix}`,
    lazy: async () => {
      const { Home } = await import('../../pages/Home/responsive/Home');
      return { Component: Home };
    },
    loader: () => null,
  })),
];

export const sharedRoutes: RouteObject[] = [
  ...homeRoutes,
  ...resultListRoutes,
  {
    path: AppRoutes.UserProfile({} as any),
    lazy: async () => {
      const { UserProfile } = await import('../../pages/user/UserProfile/UserProfile');
      return { Component: UserProfile };
    },
    loader: () => null,
  },
  {
    path: AppRoutes.Contact(),
    lazy: async () => {
      const { Contact } = await import('../../pages/Contact/Contact');
      return { Component: Contact };
    },
    loader: () => null,
  },
  {
    path: AppRoutes.PrivacyPolicy(),
    lazy: async () => {
      const { PrivacyPolicy } = await import('../../pages/PrivacyPolicy/PrivacyPolicy');
      return { Component: PrivacyPolicy };
    },
    loader: () => null,
  },
  {
    path: AppRoutes.TermsOfService(),
    lazy: async () => {
      const { TermsOfService } = await import('../../pages/TermsOfService/TermsOfService');
      return { Component: TermsOfService };
    },
    loader: () => null,
  },
];
