import { ChipTypeMap } from '@mui/material';
import React, { ForwardedRef } from 'react';
import { useDeviceMediaQuery } from 'src/hooks/useDeviceMediaQuery';
import {
  CommonAutocompleteProps,
  CommonAutocomplete,
} from 'src/ui-components/autocomplete/CommonAutocomplete';
import { MobileAutocomplete } from 'src/ui-components/autocomplete/MobileAutocomplete';
import { typedForwardRef } from 'src/utils/types/forward-ref';

export type AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
> = CommonAutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent> & {
  variant?: 'desktop';
};
const AutocompleteInner = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>(
  { variant, ...props }: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const { isDesktop } = useDeviceMediaQuery();

  if (isDesktop || variant === 'desktop') {
    return <CommonAutocomplete {...props} ref={ref} />;
  } else {
    return <MobileAutocomplete {...props} ref={ref} />;
  }
};

export const Autocomplete = typedForwardRef(AutocompleteInner);
