import { createTheme } from '@mui/material';
import { deepPurple } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: deepPurple,
    secondary: {
      main: '#f0f8ff',
      contrastText: 'black',
    },
  },
  zIndex: {
    modalHover: 1250,
  },
});

declare module '@mui/material/styles' {
  interface ZIndex {
    modalHover: number;
  }
}
