import { ChipTypeMap, useTheme } from '@mui/material';
import React, { ForwardedRef, ReactElement } from 'react';
import {
  CommonAutocomplete, CommonAutocompleteProps
} from 'src/ui-components/autocomplete/CommonAutocomplete';
import { Icon } from 'src/ui-components/Icon';
import { IconButton } from 'src/ui-components/IconButton';
import { Modal } from 'src/ui-components/Modal';
import { Stack } from 'src/ui-components/Stack';
import { TextFieldProps } from 'src/ui-components/TextField';
import { typedForwardRef } from 'src/utils/types/forward-ref';

const MobileTopAutocompleteInner = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>(
  {
    onClose,
    ...props
  }: CommonAutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent> & {
    onClose: () => void;
  },
  ref: ForwardedRef<HTMLInputElement>
) => {
  const theme = useTheme();

  return (
    <Modal open onClick={onClose} disableRestoreFocus>
      <Stack
        sx={{ background: 'white', p: 1, alignItems: 'center' }}
        spacing={1}
        onClick={(e) => e.stopPropagation()}
      >
        <IconButton size='small' sx={{ p: 0 }} onClick={onClose}>
          <Icon name='keyboard_arrow_left' />
        </IconButton>
        <CommonAutocomplete
          {...props}
          ref={ref}
          renderInput={(params) => {
            const input = props.renderInput(params) as ReactElement<TextFieldProps>;
            return React.cloneElement(input, {
              autoFocus: true,
              variant: 'outlined',
              size: 'small',
              helperText: '',
              placeholder: input.props.placeholder ?? input.props.label?.toString(),
              label: null,
            });
          }}
          open
          slotProps={{
            popper: {
              // sx won`t work
              style: { width: '100%' },
            },
            paper: {
              style: { borderRadius: 0, marginTop: theme.spacing(1) },
            },
          }}
          ListboxProps={{
            sx: { maxHeight: '60vh' },
          }}
          onChange={(e, value, reason) => {
            if (reason !== 'clear') {
              onClose();
            }

            props.onChange && props.onChange(e, value, reason);
          }}
        />
      </Stack>
    </Modal>
  );
};

export const MobileTopAutocomplete = typedForwardRef(MobileTopAutocompleteInner);
