import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import axios from 'src/config/axios-config';
import { useFileUploadQuery } from 'src/hooks/useFileUploadQuery';
import { User, UserEmailNotificationSettings, UserPrivacySettings } from 'src/models/user';
import { UserPersonalDataFormData } from 'src/pages/account/shared/UserPersonalDataFields';
import { environment } from 'src/utils/environment-utils';
import {
  genericUseMutationMethods,
  genericUseQueryMethods
} from 'src/utils/types/generic-query-methods';
import { HttpErrorData } from 'src/utils/types/http-error-data';

const baseUrl = `${environment.apiUrl}/User`;

export const getCurrentUserQueryKey = 'GetCurrentUser';
export const getCurrentUserQueryFn = () => axios.get(`${baseUrl}/GetCurrentUser`);
export const useGetCurrentUser = (
  options?: UseQueryOptions<User | null, AxiosError<HttpErrorData>>
) => {
  const query = useQuery({
    queryKey: getCurrentUserQueryKey,
    queryFn: getCurrentUserQueryFn,
    staleTime: Infinity,
    ...options,
  });

  return genericUseQueryMethods('currentUser', query);
};

export const useGetUser = (
  id: string,
  options?: UseQueryOptions<User, AxiosError<HttpErrorData>>,
  includeDeleted = true
) => {
  const query = useQuery({
    queryKey: ['GetUser', id],
    queryFn: () => {
      const params = { id, includeDeleted };
      return axios.get(`${baseUrl}/GetUser`, { params });
    },
    staleTime: Infinity,
    ...options,
  });

  return genericUseQueryMethods('user', query);
};

export const useUploadProfileImage = (
  options?: UseMutationOptions<void, AxiosError<HttpErrorData>, { image: Blob }>
) => {
  const mutation = useFileUploadQuery({
    url: `${baseUrl}/UploadProfileImage`,
    options,
  });
  return genericUseMutationMethods('uploadProfileImage', mutation);
};

export const useRemoveProfileImage = (
  options?: UseMutationOptions<void, AxiosError<HttpErrorData>, void>
) => {
  const mutation = useMutation({
    mutationFn: () => axios.delete(`${baseUrl}/RemoveProfileImage`),
    ...options,
  });
  return genericUseMutationMethods('removeProfileImage', mutation);
};

export const useChangePassword = (
  options?: UseMutationOptions<
    User,
    AxiosError<HttpErrorData>,
    { existingPassword?: string; newPassword: string }
  >
) => {
  const mutation = useMutation({
    mutationFn: (data) => {
      return axios.patch(`${baseUrl}/ChangePassword`, data);
    },
    ...options,
  });

  return genericUseMutationMethods('changePassword', mutation);
};

export const useChangePersonalData = (
  options?: UseMutationOptions<User, AxiosError<HttpErrorData>, UserPersonalDataFormData>
) => {
  const mutation = useMutation({
    mutationFn: (data) => axios.put(`${baseUrl}/ChangePersonalData`, data),
    ...options,
  });

  return genericUseMutationMethods('changePersonalData', mutation);
};

export const useChangePrivacySettings = (
  options?: UseMutationOptions<User, AxiosError<HttpErrorData>, UserPrivacySettings>
) => {
  const mutation = useMutation({
    mutationFn: (data) => axios.put(`${baseUrl}/ChangePrivacySettings`, data),
    ...options,
  });

  return genericUseMutationMethods('changePrivacySettings', mutation);
};

export const useChangeEmailNotificationSettings = (
  options?: UseMutationOptions<User, AxiosError<HttpErrorData>, UserEmailNotificationSettings>
) => {
  const mutation = useMutation({
    mutationFn: (data) => axios.put(`${baseUrl}/ChangeEmailNotificationSettings`, data),
    ...options,
  });

  return genericUseMutationMethods('changeEmailNotificationSettings', mutation);
};

export type ChangeEmailAddressData = { password?: string; email: string };
export const useSendChangeEmailAddressEmail = (
  options?: UseMutationOptions<void, AxiosError<HttpErrorData>, ChangeEmailAddressData>
) => {
  const mutation = useMutation({
    mutationFn: (data) => axios.post(`${baseUrl}/SendChangeEmailAddressEmail`, data),
    ...options,
  });

  return genericUseMutationMethods('sendChangeEmailAddressEmail', mutation);
};

export const useSendUserActivityTick = (
  options?: UseMutationOptions<void, AxiosError<HttpErrorData>, void>
) => {
  const mutation = useMutation({
    mutationFn: () => axios.patch(`${baseUrl}/SendUserActivityTick`),
    ...options,
  });

  return genericUseMutationMethods('sendUserActivityTick', mutation);
};

export const useBlockUser = (
  options?: UseMutationOptions<void, AxiosError<HttpErrorData>, User['id']>
) => {
  const mutation = useMutation({
    mutationFn: (id) => {
      const params = { id };
      return axios.post(`${baseUrl}/BlockUser`, null, { params });
    },
    ...options,
  });

  return genericUseMutationMethods('blockUser', mutation);
};

export const useUnblockUser = (
  options?: UseMutationOptions<void, AxiosError<HttpErrorData>, User['id']>
) => {
  const mutation = useMutation({
    mutationFn: (id) => {
      const params = { id };
      return axios.delete(`${baseUrl}/UnblockUser`, { params });
    },
    ...options,
  });

  return genericUseMutationMethods('unblockUser', mutation);
};
