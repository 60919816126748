import { replace, RouteObject } from 'react-router-dom';
import { AppRoutes } from 'src/router/app-routes';
import { loadCurrentUser, notLoggedInLoader } from 'src/router/loaders';

export const newAnnouncementSuffixes = [
  '23142112',
  '82913234',
  '34817293',
  '73832912',
  '73349452',
  '73839224',
  '85932912',
  '73802934',
];
const publicAnnouncementRoutes: RouteObject[] = [null, ...newAnnouncementSuffixes].map(
  (suffix) =>
    ({
      path:
        suffix !== null
          ? `${AppRoutes.NewAnnouncementPublic()}/${suffix}`
          : AppRoutes.NewAnnouncementPublic(),
      lazy: async () => {
        const { NewAnnouncementPublic } = await import(
          '../../pages/announcement/NewAnnouncementPublic/NewAnnouncementPublic'
        );
        return { Component: NewAnnouncementPublic };
      },
      // for advertisement Facebook comments
      loader: async ({ request }) => {
        const currentUser = await loadCurrentUser();
        const [, query] = request.url.split('?');
        return currentUser ? replace(`${AppRoutes.NewAnnouncement()}?${query}`) : null;
      },
    } as RouteObject)
);

export const notLoggedInRoutes: RouteObject[] = [
  {
    path: AppRoutes.Login(),
    lazy: async () => {
      const { Login } = await import('../../pages/account/Login/Login');
      return { Component: Login };
    },
    loader: notLoggedInLoader,
  },
  {
    path: AppRoutes.Register(),
    lazy: async () => {
      const { Register } = await import('../../pages/account/Register/Register');
      return { Component: Register };
    },
    loader: notLoggedInLoader,
    children: [
      {
        path: AppRoutes.RegisterRegular(),
        lazy: async () => {
          const { RegisterRegular } = await import(
            '../../pages/account/Register/sub-pages/RegisterRegular'
          );
          return { Component: RegisterRegular };
        },
        loader: notLoggedInLoader,
      },
      {
        path: AppRoutes.RegisterExternal(),
        lazy: async () => {
          const { RegisterExternal } = await import(
            '../../pages/account/Register/sub-pages/RegisterExternal'
          );
          return { Component: RegisterExternal };
        },
        loader: notLoggedInLoader,
      },
    ],
  },
  {
    path: AppRoutes.ForgotPassword(),
    lazy: async () => {
      const { ForgotPassword } = await import('../../pages/account/ForgotPassword/ForgotPassword');
      return { Component: ForgotPassword };
    },
    loader: notLoggedInLoader,
  },
  {
    path: AppRoutes.ResetPassword({} as any),
    lazy: async () => {
      const { ResetPassword } = await import('../../pages/account/ResetPassword/ResetPassword');
      return { Component: ResetPassword };
    },
    loader: notLoggedInLoader,
  },
  ...publicAnnouncementRoutes,
];
